<template>
    <div class="mt-3" v-if="detalheList != [] && detalheList.length > 0">
        <DataTable :value="detalheList" 
                :paginator="true" 
                class="p-datatable-gridlines" 
                :rows="10" 
                dataKey="id" 
                :rowHover="true" 
                responsiveLayout="stack"
                sortField="numeroContrato" 
                :sortOrder="1">
            <template #header>
                <div>
                    <Tag value="Fornecimento Futuro" class="mb-2 mt-2 font-bold text-base pl-3 pr-3" />
                </div>
                <div>
                    <label class="p-button p-component mr-2 mb-2 font-bold">Bloqueio Judicial</label>
                </div>
            </template>
            <template #empty>
                Nenhum registro encontrado.
            </template>
            <template #loading>
                Carregando... Por favor, aguarde.
            </template>
            <Column field="numeroContrato" header="Número" style="text-align: center; width:10%" sortable />
            <Column field="safra" header="Safra" style="text-align: center; width:10%" sortable />
            <Column field="modalidade" header="Modalidade" style="text-align: center; width:15%" sortable />
            <Column field="descricaoFamilia" header="Produto" style="text-align: center; width:25%" sortable />
            <Column field="dataVencimento" header="Vencimento" style="text-align: center; width:10%" sortable />
            <Column field="quantidade" header="Quantidade" style="text-align: center; width:15%" sortable />
            <Column field="quantidadeSaldoContratado" header="Saldo" style="text-align: center; width:15%" sortable />
        </DataTable>
    </div>
</template>

<script>
    import ContratoService from '../../service/ContratoService'
    import Formatacao from '../../utilities/Formatacao';

    export default {
        name: 'ContratoFornecimentoFuturo',

        props: {
			matricula: {
				type: Number,
				required: true
			}
		},

        data() {
            return {
                detalheList: [],
            }
        },

        created() {
			this.getDetalheFornecimentoFuturo();
        },

        methods: {
            getDetalheFornecimentoFuturo() {
                ContratoService.getDetalheFornecimentoFuturo(this.$props.matricula)
                    .then(({data}) => {
                        this.detalheList = data;
                        this.detalheList.map(contrato => {
                            contrato.dataVencimento = this.formatDate(contrato.dataVencimento);
                            contrato.quantidade = `${this.formatDecimal(contrato.quantidade)} kg`;
                            contrato.quantidadeSaldoContratado = `${this.formatDecimal(contrato.quantidadeSaldoContratado)} kg`;
                        });
                    });
            },

			formatDate(data) {
                return Formatacao.formatDate(data);
			},

			formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
			}
        },
    }
</script>
