<template>
    <div class="col-12">
        <div class="card">
            <h5>Contrato Detalhado</h5>
            <Button @click="$emit('showDetail', false)" icon="pi pi-backward" type="button" class="p-button-outlined mb-2" style="width: 100px;" label="Voltar"/>

            <ContratoPrecoFixo :matricula="matricula" />
            <ContratoPermuta :matricula="matricula" />
            <ContratoFornecimentoFuturo :matricula="matricula" />
            <ContratoOpcaoVenda :matricula="matricula" />
            <ContratoReciboDeposito :matricula="matricula" />
            <ContratoBloqueioJudicial :matricula="matricula" />
        </div>
    </div>
</template>

<script>
    import ContratoPrecoFixo from './ContratoPrecoFixo.vue';
    import ContratoPermuta from './ContratoPermuta.vue';
    import ContratoFornecimentoFuturo from './ContratoFornecimentoFuturo.vue';
    import ContratoOpcaoVenda from './ContratoOpcaoVenda.vue';
    import ContratoReciboDeposito from './ContratoReciboDeposito.vue';
    import ContratoBloqueioJudicial from './ContratoBloqueioJudicial.vue'

    export default {
        name: 'ContratoResumido',
        components: {ContratoPrecoFixo, ContratoPermuta, ContratoFornecimentoFuturo, ContratoOpcaoVenda, 
        ContratoReciboDeposito, ContratoBloqueioJudicial},

        props: {
			matricula: {
				type: Number,
				required: true
			}
		},      
    }
</script>