<template>
    <div class="mt-3" v-if="detalheList != [] && detalheList.length > 0">
        <DataTable :value="detalheList" 
                :paginator="true" 
                class="p-datatable-gridlines" 
                :rows="10" 
                dataKey="id" 
                :rowHover="true" 
                responsiveLayout="stack"
                sortField="numero" 
                :sortOrder="1">
            <template #header>
                <div>
                    <Tag value="Recibos de Depósitos" class="mb-2 mt-2 font-bold text-base pl-3 pr-3" />
                </div>
            </template>			             
            <template #empty>
                Nenhum registro encontrado.
            </template>
            <template #loading>
                Carregando... Por favor, aguarde.
            </template>
            <Column field="numero" header="Número" style="text-align: center; min-width:2rem" sortable />
            <Column field="nome" header="Nome" style="text-align: center; min-width:12rem" sortable />
            <Column field="banco" header="Banco" style="text-align: center; min-width:8rem" sortable />
            <Column field="agencia" header="Agência" style="text-align: center; min-width:3rem" sortable />
            <Column field="situacao" header="Situação" style="text-align: center; min-width:6rem" sortable />
            <Column field="descricaoItem" header="Descrição" style="text-align: center; min-width:12rem" sortable />
            <Column field="valor" header="Valor" style="text-align: center; min-width:4rem" sortable />
            <Column field="saldo" header="Saldo" style="text-align: center; min-width:4rem" sortable />
            <Column field="quantidadeBloqueada" header="Bloqueio" style="text-align: center; min-width:4rem" sortable />
        </DataTable>
    </div>
</template>

<script>
    import ContratoService from '../../service/ContratoService'
    import Formatacao from '../../utilities/Formatacao';

    export default {
        name: 'ContratoReciboDeposito',

        props: {
			matricula: {
				type: Number,
				required: true
			}
		},

        data() {
            return {
                detalheList: [],
            }
        },

        created() {
			this.getReciboDepositos();
        },

        methods: {
            getReciboDepositos(){
                ContratoService.getDetalheContratos(this.$props.matricula)
                    .then((response) => {                   
                        this.detalheList = response.data;
                        
                        this.detalheList.map(contrato => {
                            contrato.valor = `${this.formatCurrency(contrato.valor)}`;
                            contrato.saldo = `${this.formatDecimal(contrato.saldo)} kg`;
                            contrato.quantidadeBloqueada = `${this.formatDecimal(contrato.quantidadeBloqueada)} kg`;
                        });
                    });
            },

            formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
            },

            formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatCurrency(value, minimumFractionDigits, maximumFractionDigits);
            },
        },
    }
</script>
