<template>
    <ContratoResumido v-if="!showDetalhe" v-on:showDetail="showDetail" :matricula="currentUser.matricula"/>
    <ContratoDetalhado v-if="showDetalhe" v-on:showDetail="showDetail" :matricula="currentUser.matricula"/>
</template>

<script lang="js">
    import ContratoResumido from '../../components/extrato/ContratoResumido.vue';
    import ContratoDetalhado from '../../components/extrato/ContratoDetalhado.vue';
    
    export default {
        data(){
            return {
                showDetalhe: false,
            }
        },

        methods: {
            showDetail(value) {
                this.showDetalhe = value;                
            }
        },

        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},

        components: {ContratoResumido, ContratoDetalhado}
    }
</script>