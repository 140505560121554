<template>
    <div class="col-12">
        <div class="card">
            <h5>Contrato Resumido</h5>  
            <DataTable :value="contratoList" 
					:paginator="true" 
					class="p-datatable-gridlines" 
					:rows="10" dataKey="id" 
					:rowHover="true" 
					responsiveLayout="stack"
					sortField="dataVencimento" 
                	:sortOrder="1">
				<template #header>
					<div class="flex justify-content-between flex-column sm:flex-row">
						<Button @click="$emit('showDetail', true)" type="button" class="mr-2" label="Detalhar" icon="pi pi-search"></Button>
					</div>
				</template>
				<template #empty>
					Nenhum registro encontrado.
				</template>
				<template #loading>
					Carregando... Por favor, aguarde.
				</template>
					
                <Column field="tipo" header="Tipo" style="text-align: left; min-width:8rem" sortable>
					<template #body="slotProps">
						{{slotProps.data.tipo}}
					</template>
				</Column>	
				<Column field="dataVencimento" header="Vencimento" style="text-align: center; min-width:6rem" sortable/>
				<Column field="codigoFamilia" header="Produto" style="text-align: left; min-width:12rem" sortable>
					<template #body="slotProps">
						{{slotProps.data.codigoFamilia}}
					</template>
				</Column>
				<Column field="safra" header="Safra" style="text-align: center; min-width:6rem" sortable>
					<template #body="slotProps">
						{{slotProps.data.safra}}
					</template>
				</Column>
				<Column field="quantidadeContrato" header="Quantidade" style="text-align: right; min-width:8rem" sortable>
					<template #body="slotProps">
						{{formatDecimal(slotProps.data.quantidadeContrato)}} kg
					</template>
				</Column>
				<Column field="quantidadeSaldo" header="Saldo" style="text-align: right; min-width:8rem" sortable>
					<template #body="slotProps">
						{{formatDecimal(slotProps.data.quantidadeSaldo)}} kg
					</template>
				</Column>
				<Column field="valor" header="Valor (SC)" style="text-align: right; min-width:8rem" sortable>
					<template #body="slotProps">
						{{formatCurrency(slotProps.data.valor)}}
					</template>
				</Column>
            </DataTable>
        </div>
    </div>
</template>

<script>
    import ContratoService from '../../service/ContratoService'
    import Formatacao from '../../utilities/Formatacao';

    export default {
        name: 'ContratoResumido',

        props: {
			matricula: {
				type: Number,
				required: true
			}
		},

         data() {
            return {
                contratoList: [],
            }
        },

        created() {            
			this.getContratos();
        },

        methods: { 
            getContratos(){
                ContratoService.getContrato(this.$props.matricula)
					.then((response) => {                   
						this.contratoList = response.data;
						this.contratoList.map(contrato => contrato.dataVencimento = this.formatDate(contrato.dataVencimento));
					});
            }, 
			formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
				return Formatacao.formatCurrency(value, minimumFractionDigits, maximumFractionDigits);
			},
			formatDate(data) {                            
            	return Formatacao.formatDate(data);
			},
			formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
				return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
			}

        },        
    }
</script>

<style>
	.p-datatable-table .p-column-header-content {
        display: inline-flex !important;
    }
</style>
