<template>
    <div class="mt-3" v-if="detalheList != [] && detalheList.length > 0">
        <DataTable :value="detalheList" 
                :paginator="true" 
                class="p-datatable-gridlines" 
                :rows="10" 
                dataKey="id" 
                :rowHover="true" 
                responsiveLayout="stack"
                sortField="numeroContrato" 
                :sortOrder="1">
            <template #header>
                <div>
                    <Tag value="Opção Venda" class="mb-2 mt-2 font-bold text-base pl-3 pr-3" />
                </div>
            </template>
            <template #empty>
                Nenhum registro encontrado.
            </template>
            <template #loading>
                Carregando... Por favor, aguarde.
            </template>
            <Column field="numeroContrato" header="Número" style="text-align: center; width:15%" sortable />
            <Column field="safra" header="Safra" style="text-align: center; width:10%" sortable />
            <Column field="descricaoFamilia" header="Produto" style="text-align: center; width:30%" sortable />
            <Column field="valorTotal" header="Valor" style="text-align: center; width:15%" sortable />
            <Column field="quantidadeTaxaContratada" header="Taxa Contratada" style="text-align: center; width:15%" sortable />
            <Column field="quantidadeSaldo" header="Saldo" style="text-align: center; width:15%" sortable />
        </DataTable>
    </div>
</template>

<script>
    import ContratoService from '../../service/ContratoService'
    import Formatacao from '../../utilities/Formatacao';

    export default {
        name: 'ContratoOpcaoVenda',

        props: {
			matricula: {
				type: Number,
				required: true
			}
		},

        data() {
            return {
                detalheList: [],
            }
        },

        created() {
			this.getOpcaoVenda();
        },

        methods: {
            getOpcaoVenda() {
                ContratoService.getDetalheOpcaoVenda(this.$props.matricula)
                    .then(({data}) => {
                        this.detalheList = data;
                        this.detalheList.map(contrato => {
                            contrato.dataVencimento = this.formatDate(contrato.dataVencimento);
                            contrato.valorTotal = this.formatCurrency(contrato.valorTotal);
                            contrato.quantidadeTaxaContratada = this.formatDecimal(contrato.quantidadeTaxaContratada);
                            contrato.quantidadeSaldo = this.formatDecimal(contrato.quantidadeSaldo);
                        });
                    });
            },

            formatDate(data) {
                return Formatacao.formatDate(data);
            },

            formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
            },

            formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatCurrency(value, minimumFractionDigits, maximumFractionDigits);
            },
        },
    }
</script>
